











































import Vue from "vue";
import { mapState, mapActions } from "pinia";
import { useFolioStore } from "@/store/folioStore";
import ContentItemSummary from "@/components/contentItems/ContentItemSummary.vue";
import CreateFolioDialog from "@/components/contentItems/folios/CreateFolioDialog.vue";
import { ContentItemSummaryResult } from "@/api/DoceoApi";
import DoceoIcon from "@/components/DoceoIcon.vue";
import DOCEO_ICONS from "@/constants/icons";

export default Vue.extend({
  name: "Discover",
  metaInfo() {
    return {
      link: [{ rel: "canonical", href: `${process.env.VUE_APP_BASE_URL}${this.$route.path}` }],
    };
  },
  components: { ContentItemSummary, CreateFolioDialog, DoceoIcon },
  data: () => ({
    isCreateFolioDialogOpen: false, // Whether the create folio dialog is open or not
    DOCEO_ICONS,
  }),
  async created() {
    await this.fetchFolios();
  },
  methods: {
    ...mapActions(useFolioStore, ["fetchFolios"]),
    /**
     * Open the create folio dialog to create a new folio
     */
    createFolioClicked() {
      this.isCreateFolioDialogOpen = true;
    },
    /**
     * Event to run if the create folio dialog is closed without being confirmed/created
     */
    closeFolioDialog() {
      this.isCreateFolioDialogOpen = false;
    },
    /**
     * Event to run when the folio has been created and confirmed
     */
    createFolioConfirmed(newFolio: ContentItemSummaryResult) {
      this.isCreateFolioDialogOpen = false;
    },
  },
  computed: {
    ...mapState(useFolioStore, ["folios"]),
    newestFolios(): ContentItemSummaryResult[] {
      return [...this.folios].sort((a, b) => b.createdOn.getTime() - a.createdOn.getTime());
    },
  },
});
